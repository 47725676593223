<template>
  <div>
    <RouterForm />
  </div>
</template>

<script>
import RouterForm from "../../components/Router/RouterForm";
export default {
  components: {
    RouterForm
  }
};
</script>
